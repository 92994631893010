import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Group from '../Group';

// logo teams
import team0 from '../../assets/images/0-team.png';
import team1 from '../../assets/images/1-team.png';
import team2 from '../../assets/images/2-team.png';
import team3 from '../../assets/images/3-team.png';
import team4 from '../../assets/images/4-team.png';
import team5 from '../../assets/images/5-team.png';

import './race.scss';

const TEAM_COLORS = [
  'yellow',
  'green',
  'red',
  'blue-light',
  'blue-dark',
  'black',
];

const Race = ({ groups }) => {
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    if (groups.length) {
      calculateTeamPoints();
    }
  }, [groups]);

  const getTeamImg = (teamId) => {
    switch (teamId) {
      case 0:
        return team0;
      case 1:
        return team1;
      case 2:
        return team2;
      case 3:
        return team3;
      case 4:
        return team4;
      default:
        return team5;
    }
  };

  const getRankingPoints = (ranking) => {
    switch (ranking) {
      case 1:
        return 5;
      case 2:
        return 3;
      case 3:
        return 1;

      default:
        return 0;
    }
  };

  const calculateTeamPoints = () => {
    const tempTeams = [];

    groups.forEach((group) => {
      let segment = null;
      group.athletes.forEach((athlete) => {
        if (athlete.elapsedTime !== 28800) {
          segment = 'elapsedTime';
        }
        if ((!segment || segment === 'k4') && athlete.k8 !== 28800) {
          segment = 'k8';
        }
        if (!segment && athlete.k4 !== 28800) {
          segment = 'k4';
        }
      });

      if (segment) {
        group.athletes.forEach((athlete) => {
          const ranking =
            group.athletes.filter(
              (athleteCompare) => athlete[segment] > athleteCompare[segment]
            ).length + 1;
          let points =
            athlete[segment] === 28800 ? 0 : getRankingPoints(ranking);
          points = points - athlete.penalty;
          let tempTeam = {};
          const team = tempTeams.find((team) => team.id === athlete.team);
          if (team) {
            tempTeam = team;
            tempTeam.points = tempTeam.points + points;
          } else {
            tempTeam = {
              id: athlete.team,
              points: points,
            };
            tempTeams.push(tempTeam);
          }
        });
      }
    });

    setTeams(tempTeams.sort((a, b) => b.points - a.points));
  };
  return (
    <div className='race'>
      <div className='race-team-ranking'>
        {teams.map((team, index) => {
          return (
            <div className={`team-ranking-item`}>
              <div className={`team-img-container ${TEAM_COLORS[team.id - 1]}`}>
                <img
                  src={getTeamImg(team.id - 1)}
                  alt='team icon'
                  className={`team-img`}
                />
                {index === 0 && <div className='prize'>🥇</div>}
                {index === 1 && <div className='prize'>🥈</div>}
                {index === 2 && <div className='prize'>🥉</div>}
              </div>

              <p className='team-points'>
                {team.points}
                <span>pts</span>
              </p>
            </div>
          );
        })}
      </div>
      <div className='race-group-ranking'>
        {groups.map((group) => {
          return <Group group={group} />;
        })}
      </div>
    </div>
  );
};

Race.propTypes = {
  groups: PropTypes.array.isRequired,
};

export default Race;
