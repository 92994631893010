import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import logo from '../../assets/images/event-logo.svg';
import fileIcon from '../../assets/images/file-icon.png';
import igIcon from '../../assets/images/ig-icon.png';
// import stravaLogo from '../../assets/images/strava.svg';
import './header.scss';

const Header = () => {
  const openVideo = () => {
    window.open(
      'https://www.instagram.com/reel/C7K3AlVJwNm/?igsh=MWhyYjRkeWlpdzJlNw==',
      '_blank'
    );
  };
  const openIG = () => {
    window.open('https://www.instagram.com/tnc.community/', '_blank');
  };
  return (
    <header className='header'>
      <div className='header_profile-photo-container' onClick={openVideo}>
        <img src={fileIcon} alt='fileIcon' className='header_profile-photo' />
      </div>
      <div className='header_container_logo'>
        <img src={logo} alt='logo' className='header_logo' />
      </div>
      <div className='header_profile-photo-container' onClick={openIG}>
        <img src={igIcon} alt='igIcon' className='header_profile-photo' />
      </div>
    </header>
  );
};

Header.propTypes = {
  signUp: PropTypes.bool,
};

Header.defaultProps = {
  signUp: false,
};

export default Header;
