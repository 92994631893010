import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Team from '../Team';

import './teams.scss';

const Teams = ({ teams }) => {
  console.log('teams', teams);
  return (
    <div className='teams'>
      {teams.map((team) => {
        return <Team team={team} />;
      })}
    </div>
  );
};

Teams.propTypes = {
  teams: PropTypes.array.isRequired,
};

export default Teams;
