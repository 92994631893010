import React, { useState, useEffect } from 'react';
import Loading from '../../components/loading';
import './home.scss';
import Header from '../Header';
import Footer from '../../components/Footer';
import Teams from '../Teams';
import Race from '../Race';
import Tabs from '../Tabs';
import config from '../../config';
import { handleResponse, serializeQuery } from '../../utils/handleResponse';
import banner from '../../assets/images/banner_samsung.png';
// import bannerMobile from '../../assets/images/bannermobile.png';

const TABS = ['EQUIPOS', 'CARRERA'];

const Home = ({ history }) => {
  const [athletes, setAthletes] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [teams, setTeams] = useState([]);
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    onTabChange(0);
    getAthletes();
  }, []);

  useEffect(() => {
    if (athletes.length) {
      processTeams();
    }
  }, [athletes]);

  const [selectedTab, setSelectedTab] = useState(0);

  const processTeams = () => {
    const tempTeams = [];
    const tempGroups = [];
    athletes.forEach((athlete) => {
      let tempTeam = {};
      let tempGroup = {};
      const team = tempTeams.find((team) => team.id === athlete.team);
      const group = tempGroups.find((group) => group.id === athlete.departure);

      if (team) {
        tempTeam = team;
        tempTeam.athletes = [...tempTeam.athletes, athlete];
      } else {
        tempTeam = {
          id: athlete.team,
          athletes: [athlete],
        };
        tempTeams.push(tempTeam);
      }
      if (group) {
        tempGroup = group;
        tempGroup.athletes = [...tempGroup.athletes, athlete];
      } else {
        tempGroup = {
          id: athlete.departure,
          athletes: [athlete],
        };
        tempGroups.push(tempGroup);
      }
    });
    setTeams(tempTeams);
    setGroups(tempGroups.sort((a, b) => a.id - b.id));
  };

  const getAthletes = async (user) => {
    setShowLoading(true);
    const athlete = JSON.parse(localStorage.getItem('athleteLogged6'));
    const headers = {
      Authorization: `Bearer ${athlete.token}`,
      'Content-Type': 'application/json',
    };

    const requestOptions = {
      method: 'GET',
      headers,
    };

    const params = {
      pagination: {
        page: 1,
        limit: 1000,
      },
    };

    const url = new URL(`${config.apiUrl}/api/athletes`);
    url.search = serializeQuery(params);

    const response = await fetch(url, requestOptions);
    setShowLoading(false);
    try {
      const newAthletes = await handleResponse(response);
      setAthletes(newAthletes.docs);
    } catch (error) {
      console.log('error', error);
    }
  };

  const onTabChange = (tab) => {
    console.log('tab', tab);
    setSelectedTab(tab);
    getAthletes();
  };

  const renderTable = () => {
    console.log('selectedTab', selectedTab);
    console.log('selectedTab', selectedTab === 0);
    if (selectedTab === 0) {
      return <Teams teams={teams} />;
    } else {
      return <Race groups={groups} />;
    }
  };
  const openBanner = () => {
    window.open(
      'https://www.samsung.com/co/samsung-experience-store/locations/',
      '_blank'
    );
  };

  return (
    <div className='home'>
      {showLoading && <Loading />}
      <Header />
      <div className='banner ' onClick={openBanner}>
        <img src={banner} alt='adds' />
      </div>
      {/* <div className='banner hide-desktop'>
        <img src={bannerMobile} alt='adds' />
      </div> */}
      <div className='fixed-header'>
        <Tabs tabs={TABS} tab={3} onChange={onTabChange} />
      </div>
      {teams.length && renderTable()}
      <Footer home />
    </div>
  );
};

export default Home;
