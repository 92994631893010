import React, { useState, useEffect } from 'react';

import './login.scss';
import Loading from '../../components/loading';
import config from '../../config';
import { handleResponse, serializeQuery } from '../../utils/handleResponse';
import stravaLogo from '../../assets/images/strava_white.png';
import backgroundSamsung from '../../assets/images/background-samsung.png';
import samsungLogo from '../../assets/images/samsung-logo.svg';
import logo5gSeries from '../../assets/images/logo-5-g-series.png';

//Strava Credentials
const clientID = '109277';
const clientSecret = '9b87056bb6764464d7aece7139f1384164125936';

const Login = ({ history }) => {
  const [showLoading, setShowLoading] = useState(false);
  const [authCode, setAuthCode] = useState(false);
  const [innerHeight, setInnerHeight] = useState(false);

  useEffect(() => {
    const query = new URLSearchParams(history.location.search);
    const code = query.get('code');
    setAuthCode(code);
    setInnerHeight(window.innerHeight);
    window.addEventListener('resize', () => {
      setInnerHeight(window.innerHeight);
    });
  }, []);

  const getAccesToken = async () => {
    setShowLoading(true);
    const url = new URL(`https://www.strava.com/oauth/token`);
    const params = {
      client_id: clientID,
      client_secret: clientSecret,
      code: authCode,
      grant_type: 'authorization_code',
    };
    const requestOptions = {
      method: 'POST',
    };
    url.search = serializeQuery(params);
    const response = await fetch(url, requestOptions);
    setShowLoading(false);
    try {
      const body = await handleResponse(response);
      await getStravaAthlete(body);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    if (authCode) {
      getAccesToken();
    }
  }, [authCode]);

  // use current access token to call all activities
  const getStravaAthlete = async (access) => {
    setShowLoading(true);
    const url = new URL(`${config.stravaApiUrl}/athlete`);
    const headers = {
      Authorization: `Bearer ${access.access_token}`,
    };
    const requestOptions = {
      method: 'GET',
      headers,
    };
    const response = await fetch(url, requestOptions);
    setShowLoading(false);
    try {
      const stravaAthlete = await handleResponse(response);
      await getGuestStravaId(stravaAthlete, access);
    } catch (error) {
      console.log('error', error);
    }
  };

  const getGuestStravaId = async (stravaAthlete, access) => {
    setShowLoading(true);
    const url = new URL(`${config.apiUrl}/auth/strava-id`);
    const requestOptions = {
      method: 'GET',
    };
    const params = {
      stravaId: stravaAthlete.id,
    };
    url.search = serializeQuery(params);
    const response = await fetch(url, requestOptions);
    setShowLoading(false);
    try {
      const body = await handleResponse(response);
      if (body && body.token) {
        localStorage.setItem(
          'athleteLogged6',
          JSON.stringify({
            athlete: { ...body, isGuest: true },
            token: body.token,
          })
        );
        history.replace('/edit');
      } else {
        console.log('stravaAthlete', stravaAthlete);
        await createGuest(stravaAthlete);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const createGuest = async (stravaAthlete) => {
    setShowLoading(true);
    const url = new URL(`${config.apiUrl}/auth/sign-up-guest`);
    const headers = {
      'Content-Type': 'application/json',
    };
    const body = {
      name: stravaAthlete.firstname,
      lastName: stravaAthlete.lastname,
      stravaId: stravaAthlete.id,
      gender: stravaAthlete.sex === 'M' ? 'male' : 'female',
      cityName: stravaAthlete.city,
      countryName: stravaAthlete.country,
      stateName: stravaAthlete.state,
    };

    const requestOptions = {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
    };
    const response = await fetch(url, requestOptions);
    setShowLoading(false);
    try {
      const guest = await handleResponse(response);
      if (guest && guest.token) {
        localStorage.setItem(
          'athleteLogged6',
          JSON.stringify({
            athlete: { ...guest, isGuest: true },
            token: guest.token,
          })
        );
        history.replace('/edit');
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const login = () => {
    window.location.replace(
      `https://www.strava.com/oauth/authorize?client_id=${clientID}&response_type=code&redirect_uri=http://app.samsung5gseries.co/login&approval_prompt=force&scope=read_all,profile:read_all,activity:read_all`
    );
  };

  // const login = () => {
  //   window.location.replace(
  //     `https://www.strava.com/oauth/authorize?client_id=${clientID}&response_type=code&redirect_uri=http://localhost:3000/login&approval_prompt=force&scope=read_all,profile:read_all,activity:read_all`
  //   );
  // };

  return (
    <div className='login' style={{ minHeight: innerHeight }}>
      <div class='background-samsung'>
        <img
          className='background-samsung-img'
          src={backgroundSamsung}
          alt='backgroundSamsung'
        />
      </div>
      <div class='blue-rectangle'></div>
      <div class='background-samsung-bottom'>
        <img
          className='background-samsung-img'
          src={backgroundSamsung}
          alt='backgroundSamsung'
        />
      </div>
      {showLoading && <Loading />}
      <div className='login-content'>
        <img className='samsung-logo' src={samsungLogo} alt='samsung-logo' />
        <p className='present-text'>PRESENTA</p>
        <img
          className='logo-5-g-series'
          src={logo5gSeries}
          alt='logo-5-g-series'
        />
        <p className='info-text'>25 / 05 / 24</p>
        <p className='info-text'>ALTO DE LA CUCHILLA</p>
        <p className='description-text'>
          Conoce los equipos <br /> y resultados del evento:
        </p>

        <button className='login_button' onClick={login}>
          Conectarse con
          <img src={stravaLogo} alt='strava' className='strava-logo' />
        </button>
      </div>
    </div>
  );
};

export default Login;
