import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import downIcon from '../../assets/images/down-chevron.svg';
import upIcon from '../../assets/images/up-chevron.svg';
import stravaLogo from '../../assets/images/strava.svg';
import defaultFoto from '../../assets/images/foto.jpeg';

// logo teams
import team0 from '../../assets/images/0-team.png';
import team1 from '../../assets/images/1-team.png';
import team2 from '../../assets/images/2-team.png';
import team3 from '../../assets/images/3-team.png';
import team4 from '../../assets/images/4-team.png';
import team5 from '../../assets/images/5-team.png';
// logo athletes
// team 1
import athlete1team1 from '../../assets/images/athlete1team1.png';
import athlete2team1 from '../../assets/images/athlete2team1.jpeg';
import athlete3team1 from '../../assets/images/athlete3team1.jpg';
import athlete4team1 from '../../assets/images/athlete4team1.jpg';
import athlete5team1 from '../../assets/images/athlete5team1.jpg';
// team 2
import athlete1team2 from '../../assets/images/athlete1team2.jpg';
import athlete2team2 from '../../assets/images/athlete2team2.jpg';
import athlete3team2 from '../../assets/images/athlete3team2.jpg';
import athlete4team2 from '../../assets/images/athlete4team2.jpg';
import athlete5team2 from '../../assets/images/athlete5team2.jpg';
// team 3
import athlete1team3 from '../../assets/images/athlete1team3.jpeg';
import athlete2team3 from '../../assets/images/athlete2team3.jpeg';
import athlete3team3 from '../../assets/images/athlete3team3.jpg';
import athlete4team3 from '../../assets/images/athlete4team3.jpeg';
import athlete5team3 from '../../assets/images/athlete5team3.jpeg';
// team 4
import athlete1team4 from '../../assets/images/athlete1team4.jpeg';
import athlete2team4 from '../../assets/images/athlete2team4.jpeg';
import athlete3team4 from '../../assets/images/athlete3team4.jpeg';
import athlete4team4 from '../../assets/images/athlete4team4.jpeg';
import athlete5team4 from '../../assets/images/athlete5team4.jpg';
// team 5
import athlete1team5 from '../../assets/images/athlete1team5.jpg';
import athlete2team5 from '../../assets/images/athlete2team5.jpg';
import athlete3team5 from '../../assets/images/athlete3team5.jpg';
import athlete4team5 from '../../assets/images/athlete4team5.jpeg';
import athlete5team5 from '../../assets/images/athlete5team5.jpg';
// team 6
import athlete1team6 from '../../assets/images/athlete1team6.jpg';
import athlete2team6 from '../../assets/images/athlete2team6.jpg';
import athlete3team6 from '../../assets/images/athlete3team6.jpg';
import athlete4team6 from '../../assets/images/athlete4team6.jpg';
import athlete5team6 from '../../assets/images/athlete5team6.jpg';

import './team.scss';

const TEAM_NAMES = [
  'RUTA 56',
  'QUATTRO VATIOS',
  'ESCARABAJOS',
  'CYCLA X EGAN',
  '92 CYCLING',
  'SAMSUNG',
];

const TEAM_COLORS = [
  'yellow',
  'green',
  'red',
  'blue-light',
  'blue-dark',
  'black',
];

const Team = ({ team }) => {
  const [isOpen, setIsOpen] = useState(false);
  const openAcordion = () => {
    setIsOpen(!isOpen);
  };
  const openStravaProfile = (stravaId) => {
    window.open(`https://www.strava.com/athletes/${stravaId}`, '_newtab');
  };

  const getTeamImg = (teamId) => {
    switch (teamId) {
      case 0:
        return team0;
      case 1:
        return team1;
      case 2:
        return team2;
      case 3:
        return team3;
      case 4:
        return team4;
      default:
        return team5;
    }
  };

  const getAthleteImg = (teamId, athleteId) => {
    switch (teamId) {
      case 1:
        switch (athleteId) {
          case 1:
            return athlete1team1;
          case 2:
            return athlete2team1;
          case 3:
            return athlete3team1;
          case 4:
            return athlete4team1;

          default:
            return athlete5team1;
        }
      case 2:
        switch (athleteId) {
          case 1:
            return athlete1team2;
          case 2:
            return athlete2team2;
          case 3:
            return athlete3team2;
          case 4:
            return athlete4team2;

          default:
            return athlete5team2;
        }
      case 3:
        switch (athleteId) {
          case 1:
            return athlete1team3;
          case 2:
            return athlete2team3;
          case 3:
            return athlete3team3;
          case 4:
            return athlete4team3;

          default:
            return athlete5team3;
        }
      case 4:
        switch (athleteId) {
          case 1:
            return athlete1team4;
          case 2:
            return athlete2team4;
          case 3:
            return athlete3team4;
          case 4:
            return athlete4team4;

          default:
            return athlete5team4;
        }
      case 5:
        switch (athleteId) {
          case 1:
            return athlete1team5;
          case 2:
            return athlete2team5;
          case 3:
            return athlete3team5;
          case 4:
            return athlete4team5;

          default:
            return athlete5team5;
        }
      default:
        switch (athleteId) {
          case 1:
            return athlete1team6;
          case 2:
            return athlete2team6;
          case 3:
            return athlete3team6;
          case 4:
            return athlete4team6;

          default:
            return athlete5team6;
        }
    }
  };
  return (
    <div className='team' onClick={openAcordion}>
      <div
        className={`team-header ${TEAM_COLORS[team.id - 1]} ${
          isOpen ? 'open' : ''
        }`}
      >
        <img src={getTeamImg(team.id - 1)} alt='team icon' />
        <p className='team-name'>{TEAM_NAMES[team.id - 1]}</p>
        <img
          src={isOpen ? upIcon : downIcon}
          alt='down'
          className='down-icon'
        />
      </div>
      {isOpen &&
        team.athletes.map((athlete) => {
          return (
            <div
              className={`athlete ${athlete.stravaId ? 'stravaId' : ''}`}
              onClick={() => {
                if (athlete.stravaId) {
                  openStravaProfile(athlete.stravaId);
                }
              }}
            >
              <img
                src={getAthleteImg(team.id, athlete.athleteId)}
                alt='athlete'
                className='athlete-photo'
              />
              <p className='athlete-name'>{`${athlete.name} ${athlete.lastName}, ${athlete.age}`}</p>
              {athlete.stravaId && (
                <img
                  src={stravaLogo}
                  alt='strava logo'
                  className='strava-icon'
                />
              )}
            </div>
          );
        })}
    </div>
  );
};

Team.propTypes = {
  team: PropTypes.object.isRequired,
};

export default Team;
