import React, { useState, useEffect } from 'react';
import { TextField, FormControl } from '@material-ui/core';

import { Row, Col } from 'react-flexbox-grid';
import Footer from '../../components/Footer';

import './editAthlete.scss';
import Loading from '../../components/loading';
import config from '../../config';
import { handleResponse } from '../../utils/handleResponse';
import camera from '../../assets/images/camara.svg';
import threearrow from '../../assets/images/3arrow.svg';
import Header from '../Header';

const EditAthlete = ({ history }) => {
  const [showLoading, setShowLoading] = useState(false);
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [currentGuest, setCurrentGuest] = useState();

  useEffect(() => {
    if (currentGuest) {
      setName(currentGuest.name);
      setLastName(currentGuest.lastName);
      setEmail(currentGuest.email);
    }
  }, [currentGuest]);

  useEffect(() => {
    const athlete = JSON.parse(localStorage.getItem('athleteLogged6'));
    if (athlete && athlete.athlete) {
      console.log('athlete', athlete);
      setCurrentGuest(athlete.athlete.guest);
    }
  }, []);

  const updateAthlete = async () => {
    setShowLoading(true);
    const athlete = JSON.parse(localStorage.getItem('athleteLogged6'));
    const url = new URL(
      `${config.apiUrl}/api/athletes/guest/${athlete.athlete.guest._id}`
    );
    const headers = {
      Authorization: `Bearer ${athlete.token}`,
      'Content-Type': 'application/json',
    };
    const body = {
      email,
    };

    const requestOptions = {
      method: 'PUT',
      headers,
      body: JSON.stringify(body),
    };
    const response = await fetch(url, requestOptions);
    setShowLoading(false);
    try {
      const guest = await handleResponse(response);
      console.log('guest', guest);
      localStorage.setItem(
        'athleteLogged6',
        JSON.stringify({
          athlete: { guest, isGuest: true },
          token: athlete.token,
        })
      );
      history.replace('/');
    } catch (error) {
      console.log('error', error);
    }
  };

  const save = () => {
    updateAthlete();
  };

  const changeInput = (inputSet) => (event) => {
    inputSet(event.target.value);
  };

  return (
    <div className='edit-athlete'>
      {showLoading && <Loading />}
      <Header editAthlete />
      <Col xsOffset={1} xs={10} md={6} mdOffset={3}>
        <Row>
          <Col xs={12}>
            <div className='edit-title-container'>
              <div className='edit-title'>
                INFORMACIÓN
                <br /> PERSONAL
              </div>
              <img className='three-arrow' src={threearrow} alt='logo' />
            </div>
          </Col>
        </Row>
      </Col>
      <Col xsOffset={1} xs={10} md={6} mdOffset={3}>
        <Row>
          <Col xs={12} md={6} className='field-container'>
            <FormControl fullWidth>
              <TextField
                onChange={changeInput(setName)}
                value={name}
                id='name'
                disabled
                label='Nombre'
              />
            </FormControl>
          </Col>
          <Col xs={12} md={6} className='field-container'>
            <FormControl fullWidth>
              <TextField
                onChange={changeInput(setLastName)}
                value={lastName}
                id='lastName'
                disabled
                label='Apellido'
              />
            </FormControl>
          </Col>
          <Col xs={12} className='field-container'>
            <FormControl fullWidth>
              <TextField
                onChange={changeInput(setEmail)}
                value={email}
                id='email'
                label='Email'
              />
            </FormControl>
          </Col>

          <Col xs={12} md={6} mdOffset={3}>
            <button
              onClick={save}
              className='submit-button filter-button'
              disabled={!!!email}
            >
              Guardar
            </button>
          </Col>
        </Row>
      </Col>
      <Footer />
    </div>
  );
};

export default EditAthlete;
