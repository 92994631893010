import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import downIcon from '../../assets/images/down-chevron.svg';
import upIcon from '../../assets/images/up-chevron.svg';
import defaultFoto from '../../assets/images/foto.jpeg';

// logo teams
import team0 from '../../assets/images/0-team.png';
import team1 from '../../assets/images/1-team.png';
import team2 from '../../assets/images/2-team.png';
import team3 from '../../assets/images/3-team.png';
import team4 from '../../assets/images/4-team.png';
import team5 from '../../assets/images/5-team.png';

// logo athletes
// team 1
import athlete1team1 from '../../assets/images/athlete1team1.png';
import athlete2team1 from '../../assets/images/athlete2team1.jpeg';
import athlete3team1 from '../../assets/images/athlete3team1.jpg';
import athlete4team1 from '../../assets/images/athlete4team1.jpg';
import athlete5team1 from '../../assets/images/athlete5team1.jpg';
// team 2
import athlete1team2 from '../../assets/images/athlete1team2.jpg';
import athlete2team2 from '../../assets/images/athlete2team2.jpg';
import athlete3team2 from '../../assets/images/athlete3team2.jpg';
import athlete4team2 from '../../assets/images/athlete4team2.jpg';
import athlete5team2 from '../../assets/images/athlete5team2.jpg';
// team 3
import athlete1team3 from '../../assets/images/athlete1team3.jpeg';
import athlete2team3 from '../../assets/images/athlete2team3.jpeg';
import athlete3team3 from '../../assets/images/athlete3team3.jpg';
import athlete4team3 from '../../assets/images/athlete4team3.jpeg';
import athlete5team3 from '../../assets/images/athlete5team3.jpeg';
// team 4
import athlete1team4 from '../../assets/images/athlete1team4.jpeg';
import athlete2team4 from '../../assets/images/athlete2team4.jpeg';
import athlete3team4 from '../../assets/images/athlete3team4.jpeg';
import athlete4team4 from '../../assets/images/athlete4team4.jpeg';
import athlete5team4 from '../../assets/images/athlete5team4.jpg';
// team 5
import athlete1team5 from '../../assets/images/athlete1team5.jpg';
import athlete2team5 from '../../assets/images/athlete2team5.jpg';
import athlete3team5 from '../../assets/images/athlete3team5.jpg';
import athlete4team5 from '../../assets/images/athlete4team5.jpeg';
import athlete5team5 from '../../assets/images/athlete5team5.jpg';
// team 6
import athlete1team6 from '../../assets/images/athlete1team6.jpg';
import athlete2team6 from '../../assets/images/athlete2team6.jpg';
import athlete3team6 from '../../assets/images/athlete3team6.jpg';
import athlete4team6 from '../../assets/images/athlete4team6.jpg';
import athlete5team6 from '../../assets/images/athlete5team6.jpg';

// tab icon
import flag from '../../assets/images/flag.png';
import watch from '../../assets/images/watch.png';
import greenflag from '../../assets/images/green-flag.png';

import './group.scss';

const TEAM_COLORS = [
  'yellow',
  'green',
  'red',
  'blue-light',
  'blue-dark',
  'black',
];
const SEGMETS = ['none', 'k4', 'k8', 'elapsedTime'];

const Group = ({ group }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [tab, setTab] = useState(1);
  const [tabDefault, setTabDefault] = useState(1);
  const [athletes, setAthletes] = useState([]);
  const openAcordion = () => {
    setIsOpen(!isOpen);
  };
  const getRankingPoints = (ranking) => {
    switch (ranking) {
      case 1:
        return 5;
      case 2:
        return 3;
      case 3:
        return 1;

      default:
        return 0;
    }
  };
  const sortAthletes = () => {
    const tempAthletes = [];
    if (tab) {
      group.athletes.forEach((athlete) => {
        const ranking =
          group.athletes.filter(
            (athleteCompare) =>
              athlete[SEGMETS[tab]] > athleteCompare[SEGMETS[tab]]
          ).length + 1;

        const rakingPoints =
          athlete[SEGMETS[tab]] === 28800 ? 0 : getRankingPoints(ranking);

        const points = tab <= tabDefault ? rakingPoints : 0;

        tempAthletes.push({
          ...athlete,
          ranking,
          points,
        });
      });
    }

    setAthletes(tempAthletes.sort((a, b) => a[SEGMETS[tab]] - b[SEGMETS[tab]]));
  };
  const calculateRankingPoints = () => {
    let segmentNumber = 0;
    group.athletes.forEach((athlete) => {
      if (athlete.elapsedTime !== 28800) {
        segmentNumber = 3;
      }
      if (!segmentNumber && athlete.k8 !== 28800) {
        segmentNumber = 2;
      }
      if (!segmentNumber && athlete.k4 !== 28800) {
        segmentNumber = 1;
      }
    });

    setTab(segmentNumber);
    setTabDefault(segmentNumber);
  };
  useEffect(() => {
    if (group) {
      calculateRankingPoints();
    }
  }, [group]);

  useEffect(() => {
    if (tab) {
      sortAthletes();
    }
  }, [tab]);

  const getTeamImg = (teamId) => {
    switch (teamId) {
      case 0:
        return team0;
      case 1:
        return team1;
      case 2:
        return team2;
      case 3:
        return team3;
      case 4:
        return team4;
      default:
        return team5;
    }
  };
  const getAthleteImg = (teamId, athleteId) => {
    switch (teamId) {
      case 1:
        switch (athleteId) {
          case 1:
            return athlete1team1;
          case 2:
            return athlete2team1;
          case 3:
            return athlete3team1;
          case 4:
            return athlete4team1;

          default:
            return athlete5team1;
        }
      case 2:
        switch (athleteId) {
          case 1:
            return athlete1team2;
          case 2:
            return athlete2team2;
          case 3:
            return athlete3team2;
          case 4:
            return athlete4team2;

          default:
            return athlete5team2;
        }
      case 3:
        switch (athleteId) {
          case 1:
            return athlete1team3;
          case 2:
            return athlete2team3;
          case 3:
            return athlete3team3;
          case 4:
            return athlete4team3;

          default:
            return athlete5team3;
        }
      case 4:
        switch (athleteId) {
          case 1:
            return athlete1team4;
          case 2:
            return athlete2team4;
          case 3:
            return athlete3team4;
          case 4:
            return athlete4team4;

          default:
            return athlete5team4;
        }
      case 5:
        switch (athleteId) {
          case 1:
            return athlete1team5;
          case 2:
            return athlete2team5;
          case 3:
            return athlete3team5;
          case 4:
            return athlete4team5;

          default:
            return athlete5team5;
        }
      default:
        switch (athleteId) {
          case 1:
            return athlete1team6;
          case 2:
            return athlete2team6;
          case 3:
            return athlete3team6;
          case 4:
            return athlete4team6;

          default:
            return athlete5team6;
        }
    }
  };

  const showTime = (_id, timeDate, status, firstRaking) => {
    const waiting = status === 1 || timeDate === 28800;
    const dnf = status === 2;
    const dq = status === 3;
    const validTime = status === 0 && !dnf && !dq && !waiting;

    return (
      <div>
        {dnf && 'DNF'}
        {dq && 'DQ'}
        {waiting && 'Esperando tiempo'}
        {validTime &&
          firstRaking._id === _id &&
          moment.utc(timeDate * 1000).format('HH:mm:ss')}
        {validTime &&
          firstRaking._id !== _id &&
          `+ ${moment
            .utc((timeDate - firstRaking[SEGMETS[tab]]) * 1000)
            .format('HH:mm:ss')}`}
      </div>
    );
  };

  return (
    <div className='group'>
      <div
        className={`group-header  ${isOpen ? 'open' : ''}`}
        onClick={openAcordion}
      >
        <div className='order-container'>
          <p className='order-text'>{group.id}</p>
          <p className='order-time'>
            {group.athletes[0].startTime} <span>am</span>
          </p>
        </div>
        <div className='athletes-container'>
          {group.athletes.map((athlete) => {
            return (
              <div
                className={`athlete-photo-container ${
                  TEAM_COLORS[athlete.team - 1]
                }`}
              >
                <img
                  src={getAthleteImg(athlete.team, athlete.athleteId)}
                  alt='athlete'
                  className='athlete-photo'
                />
              </div>
            );
          })}
        </div>
        <div className='down-icon-container'>
          <img
            src={isOpen ? upIcon : downIcon}
            alt='down'
            className='down-icon'
          />
        </div>
      </div>
      {isOpen && (
        <>
          <div className='group-tabs'>
            <div>
              <img
                src={watch}
                alt='athlete'
                className='watch-icon'
                onClick={() => {
                  setTab(0);
                }}
              />
            </div>
            <div className={`first-segment ${tab > 0 ? 'selected' : ''}`} />
            <div>
              <div
                className={`segment-tab ${tab === 1 ? 'selected' : ''}`}
                onClick={() => {
                  setTab(1);
                }}
              >
                4K
              </div>
            </div>
            <div className={`medium-segment ${tab > 1 ? 'selected' : ''}`} />
            <div>
              <div
                className={`segment-tab ${tab === 2 ? 'selected' : ''}`}
                onClick={() => {
                  setTab(2);
                }}
              >
                8K
              </div>
            </div>
            <div className={`last-segment ${tab > 2 ? 'selected' : ''}`} />
            <div className='flag-icon-container'>
              {tab === 3 ? (
                <img
                  src={greenflag}
                  alt='athlete'
                  className='flag-icon-green'
                  onClick={() => {
                    setTab(3);
                  }}
                />
              ) : (
                <img
                  src={flag}
                  alt='athlete'
                  className='flag-icon'
                  onClick={() => {
                    setTab(3);
                  }}
                />
              )}
            </div>
          </div>
          <div className='athlete-ranking-container'>
            {athletes.map((athlete, index) => {
              return (
                <div className='athlete-ranking-item'>
                  <div
                    className={`athlete-ranking-photos ${
                      TEAM_COLORS[athlete.team - 1]
                    }`}
                  >
                    <p>{index + 1}</p>
                    <img
                      src={getTeamImg(athlete.team - 1)}
                      alt='team icon'
                      className={`team-img`}
                    />
                    <img
                      src={getAthleteImg(athlete.team, athlete.athleteId)}
                      alt='athlete'
                      className='athlete-photo'
                    />
                  </div>
                  <div className='athlete-name-size-container'>
                    <div className='athlete-name-size'>
                      <div className='athlete-name-container'>
                        <p className='athlete-name'>{`${athlete.name} ${athlete.lastName}`}</p>
                        {tab > 0 && tab <= tabDefault && (
                          <p className='athlete-points'>
                            {athlete.points}
                            <span>pts</span>
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  {tab > 0 && tab <= tabDefault && (
                    <div className='prize-container'>
                      {index === 0 && athlete[SEGMETS[tab]] !== 28800 && (
                        <div className='prize'>🥇</div>
                      )}
                      {index === 1 && athlete[SEGMETS[tab]] !== 28800 && (
                        <div className='prize'>🥈</div>
                      )}
                      {index === 2 && athlete[SEGMETS[tab]] !== 28800 && (
                        <div className='prize'>🥉</div>
                      )}
                      {index > 2 && <div className='prize'> </div>}
                    </div>
                  )}
                  {tab > 0 && tab <= tabDefault && (
                    <div className={`time-container`}>
                      <div
                        className={`time-flag ${index === 0 ? 'first' : ''}`}
                      >
                        {showTime(
                          athlete._id,
                          athlete[SEGMETS[tab]],
                          athlete.status,
                          athletes[0]
                        )}
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

Group.propTypes = {
  group: PropTypes.object.isRequired,
};

export default Group;
