import React, { useState } from 'react';
import './tabs.scss';
import PropTypes from 'prop-types';

const Tabs = ({ tabs, onChange }) => {
  const [selected, setSelected] = useState(0);

  return (
    <div className='tabs'>
      <div className='tabs-container'>
        {tabs.map((tab, index) => {
          // const className =
          //   index === 1 ? ' disabled' : index === selected ? ' selected' : '';
          const className = index === selected ? ' selected' : '';
          return (
            <div
              key={tab}
              className={`tab${className}`}
              onClick={() => {
                // if (index !== 1) {
                setSelected(index);
                onChange(index);
                // }
              }}
            >
              <div>{tab}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Tabs;
